import React, {FunctionComponent} from 'react';
import HtmlHead from '../components/layout/HtmlHead';

const NotFoundPage: FunctionComponent = () => {
    return (
        <>
            <HtmlHead pageTitle={'404'}/>
            <main className='container px-4 mx-auto my-8'>
                <p>Deze pagina bestaat niet.</p>
            </main>
        </>
    );
};

export default NotFoundPage;
